import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

@Component({})
export default class AccountDropdown extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;

  @Inject('accountService')
  private accountService: () => AccountService;

  @Prop() changePasswordURL: string;

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get isUser(): boolean {
    return this.$store.getters.account?.authorities?.includes('ROLE_USER');
  }

  public get isClockedIn(): boolean {
    return this.$store.getters.isClockedIn;
  }

  public get firstName(): string {
    return this.$store.getters.account?.firstName ?? '';
  }

  public get lastName(): string {
    return this.$store.getters.account?.lastName ?? '';
  }

  public get showInitials(): string {
    const firstNameLetter = this.firstName[0].toUpperCase();
    const lastNameLetter = this.lastName[0].toUpperCase();
    return firstNameLetter + lastNameLetter;
  }

  public get isOnBreak(): boolean {
    return this.$store.getters.isOnBreak;
  }

  public changePasswordClick(): void {
    window.open(this.changePasswordURL, '_blank', 'noopener');
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public async logout() {
    if (this.isClockedIn) this.$bvModal.show('logOut');
    else {
      await this.defaultLogout();
    }
  }

  public async defaultLogout() {
    dayjs.extend(isSameOrAfter);
    if (!this.$store.getters.account?.authorities?.includes('ROLE_ADMIN') && this.userId) {
      await this.saveUserWidgets();
    }
    if (dayjs().isSameOrAfter(this.lastShiftEndTime)) {
      await this.deleteBreakHistory();
    }

    const response = await this.loginService().logout();
    this.$store.commit('logout');
    window.location.href = response.data.logoutUrl;
    return Promise.resolve(this.$router.currentRoute);
  }

  public async deleteBreakHistory(): Promise<void> {
    await axios.delete(`/api/breakHistory/interpreter/${this.userID}`);
  }

  public get userID(): string {
    return this.$store.getters.account.id;
  }

  public hideModal() {
    this.$bvModal.hide('logOut');
  }

  public get lastShiftEndTime(): string {
    return this.$store.getters.lastShiftEndTime;
  }

  public async confirmLogOut() {
    await axios.put('/api/clockOff').then(() => {
      this.defaultLogout();
      this.$store.commit('setIsClockedIn', false);
    });
  }

  async saveUserWidgets() {
    const layouts = [];
    this.userWidgets.forEach(item => {
      const layout = {
        name: item.name,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        path: item.path,
      };
      layouts.push(layout);
    });

    const payload = {
      interpreterId: this.userId,
      layouts,
    };
    await this.$store.dispatch('saveWidgets', payload);
  }

  public get userWidgets(): any[] {
    return this.$store.getters.getUserWidgets;
  }

  public get userId(): string {
    return this.$store.getters.account?.id || '';
  }
}
