var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "pointer",
      attrs: {
        right: "",
        id: "entity-menu",
        "menu-class": "black",
        "data-cy": "entity",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "navbar-dropdown-menu",
          class: {
            "active-link": _vm.subIsActive([
              "/users",
              "/application",
              "/region",
              "/announcements",
              "/vi/team/details",
            ]),
          },
          attrs: { slot: "button-content" },
          slot: "button-content",
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "th-list" } }),
          _vm._v(" "),
          _c("span", { staticClass: "no-bold" }, [_vm._v("Entities")]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.menuItems, function (item, key) {
        return _c("b-dropdown-item", { key: key, attrs: { to: item.link } }, [
          _c("span", [_vm._v(_vm._s(item.name))]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }