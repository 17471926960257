// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ModalPlugin, PaginationPlugin, ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import VueSecureHTML from 'vue-html-secure';

import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import BreakTrackerService from "@/core/brake-tracker/break-tracker.service";
/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome();
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(PaginationPlugin);
Vue.use(VueSecureHTML);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const store = config.initVueXStore(Vue);

const loginService = new LoginService();
const accountService = new AccountService(store, (<any>Vue).cookie, router);
const trackerService = new BreakTrackerService();

router.beforeEach(async (to, from, next) => {
  const token = accountService.cookie.get('JSESSIONID') || accountService.cookie.get('XSRF-TOKEN');
  if (!store.getters.account && token) {
    await accountService.retrieveAccount();
  }

  if (!to.matched.length) {
    next('/not-found');
  } else {
    if (to.meta && to.meta.authorities && to.meta.authorities.length) {
      const authority = store.getters.account?.authorities;
      if (authority) {
        if (to.meta.authorities.includes(authority[0]) && store.getters.authenticated) {
          next();
        } else {
          sessionStorage.setItem('requested-url', to.fullPath);
          next('/forbidden');
        }
      } else {
        next('/');
      }
    } else {
      if (to.path === '/' && store.getters.authenticated) {
        next('/home');
      } else {
        next();
      }
    }
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),
    trackerService: () => trackerService,

    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    alertService: () => new AlertService(),
  },
  store,
});

setupAxiosInterceptors(
  error => {
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;

    if (status === 401) {
      if (url.includes('api/announcements/region') || url.endsWith('api/shift') || url.includes('api/courses/interpreter')) {
        // Store logged out state.
        store.commit('logout');
        router.push('/');
        return Promise.reject('Session expired');
      }
      // Store logged out state.
      store.commit('logout');
      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate') && !url.endsWith('api/expired')) {
        // Ask for a new authentication
        const port = window.location.port ? ':' + window.location.port : '';

        const loc = `//${window.location.hostname}${port}/oauth2/authorization/oidc`;
        window.location.replace(loc);
        return;
      }
    }
    console.log('Unauthorized!');
    return Promise.reject(error);
  },
  error => {
    const alert = new AlertService();
    alert.showError(this, error.response?.data?.message ||
      'Last operation was not successful, please try to repeat it and contact IT support.');
    console.log('Server error!');
    return Promise.reject(error);
  }
);
