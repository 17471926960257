import { ActionTree } from "vuex";
import { AnnouncementsState } from "@/shared/config/store/dashboard/admin/announcements/types";
import axios from "axios";

export const actions: ActionTree<AnnouncementsState, any> = {
  async createAnnouncement(state, payload) {
    const response = await axios.post(`api/announcements`, payload);
    state.commit("SET_ANNOUNCEMENTS", response.data);
  },
  async saveAnnouncement(state, { announcementId, payload }) {
    const response = await axios.put(`api/announcements/${ announcementId }`, payload);
    state.commit("SET_ANNOUNCEMENTS", response.data);
  },
  async loadAnnouncement(state, { regionName, subregionName }) {
    const response = await axios.get(`/api/announcements/region`, {
      params: {
        regionName: regionName,
        subregionName: subregionName
      }
    });

    state.commit("SET_ANNOUNCEMENTS", response.data);
  }
};
