var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c("company-details"),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { "is-nav": "", id: "header-tabs" } }, [
        _c(
          "div",
          {
            staticClass:
              "ml-auto d-flex align-items-center justify-content-center",
          },
          [
            _c(
              "b-navbar-nav",
              [
                _vm.isUser
                  ? _c(
                      "b-nav-item",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.openAnnouncement()
                          },
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "bell-size",
                          attrs: { icon: "fa-bell" },
                        }),
                        _vm._v(" "),
                        !_vm.isReadAnnouncement
                          ? _c(
                              "b-badge",
                              { attrs: { pill: "", variant: "primary" } },
                              [_vm._v("1")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "announcement",
                              title:
                                _vm.announcement.title !== null
                                  ? _vm.announcement.title
                                  : "Title of announcement",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "modal-footer",
                                  fn: function (ref) {
                                    var cancel = ref.cancel
                                    return [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-primary" },
                                          on: {
                                            click: function ($event) {
                                              return cancel()
                                            },
                                          },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: { click: _vm.onReadAnnouncement },
                                        },
                                        [_vm._v("Ok")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3876805391
                            ),
                          },
                          [
                            _c("div", {
                              directives: [
                                {
                                  name: "html-safe",
                                  rawName: "v-html-safe",
                                  value:
                                    _vm.announcement.content !== null
                                      ? _vm.announcement.content
                                      : "No announcement was created",
                                  expression:
                                    "announcement.content !== null ? announcement.content : 'No announcement was created'",
                                },
                              ],
                              staticStyle: { "word-wrap": "break-word" },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticated ? _c("home-link") : _vm._e(),
                _vm._v(" "),
                _vm.isAdmin && _vm.authenticated
                  ? _c("entities-menu")
                  : _vm._e(),
                _vm._v(" "),
                _vm.isDeveloper && _vm.authenticated
                  ? _c("admin-dropdown")
                  : _vm._e(),
                _vm._v(" "),
                _c("account-dropdown", {
                  attrs: { changePasswordURL: _vm.changePasswordURL },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }