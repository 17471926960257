import { Component, Vue } from 'vue-property-decorator';
import router from '@/router';

@Component({})
export default class CompanyDetails extends Vue {
  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public redirectPage(): void {
    if (this.authenticated) {
      if (router.currentRoute.path !== '/home') router.push('/home');
    } else {
      if (router.currentRoute.path !== '/') router.push('/');
    }
  }
}
