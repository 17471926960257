import { Module } from 'vuex';

export interface BreakStore {
  activeBreaks: any;
  plannedBreaks: any;
}

export const breakStore: Module<BreakStore, any> = {
  state: {
    activeBreaks: undefined,
    plannedBreaks: undefined,
  },
  getters: {
    activeBreaks: state => state.activeBreaks,
    plannedBreaks: state => state.plannedBreaks,
  },
  mutations: {
    setInfo(state, breaksInfo) {
      state.activeBreaks = breaksInfo.activeBreaks?.data;
      state.plannedBreaks = breaksInfo.plannedBreaks?.data;
    },
  },
};
