var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-brand",
    {
      staticClass: "logo",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.redirectPage()
        },
      },
    },
    [
      _c("img", { attrs: { src: require("./assets/logo.svg"), alt: "Logo" } }),
      _vm._v(" "),
      _c("span", { staticClass: "navbar-title" }, [_vm._v("VI Tool Chest")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }