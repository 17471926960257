import { Authority } from '@/shared/security/authority';

const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const Announcements = () => import('@/admin/announcements/announcements.vue');
const VITeamDetails = () => import('@/admin/break/vi/team/details/vi-team-details.vue');

export default [
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: {
      authorities: [Authority.DEVELOPER],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'API',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vi/team/details',
    name: 'VI Team details',
    component: VITeamDetails,
    meta: {
      authorities: [Authority.ADMIN],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        { text: 'VI Team Details' },
      ],
    },
  },
  {
    path: '/admin/health',
    name: 'JhiHealthComponent',
    component: JhiHealthComponent,
    meta: {
      authorities: [Authority.DEVELOPER],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'Health',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/logs',
    name: 'JhiLogsComponent',
    component: JhiLogsComponent,
    meta: {
      authorities: [Authority.DEVELOPER],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'Logs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/metrics',
    name: 'JhiMetricsComponent',
    component: JhiMetricsComponent,
    meta: {
      authorities: [Authority.DEVELOPER],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'Metrics',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/configuration',
    name: 'JhiConfigurationComponent',
    component: JhiConfigurationComponent,
    meta: {
      authorities: [Authority.DEVELOPER],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: Announcements,
    meta: {
      authorities: [Authority.ADMIN],
      breadCrumb: [
        {
          text: 'Home',
          to: { name: 'Dashboard' },
        },
        {
          text: 'Announcements',
          active: true,
        },
      ],
    },
  },
];
