import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';

import EntitiesMenu from '@/entities/entities-menu.vue';
import CompanyDetails from '@/core/jhi-navbar/components/CompanyDetails/companyDetails.vue';
import AdminDropdown from '@/core/jhi-navbar/components/AdminDropdown/adminDropdown.vue';
import AccountDropdown from '@/core/jhi-navbar/components/AccountDropdown/accountDropdown.vue';
import HomeLink from '@/core/jhi-navbar/components/HomeLink/homeLink.vue';
import axios from 'axios';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    'company-details': CompanyDetails,
    'admin-dropdown': AdminDropdown,
    'account-dropdown': AccountDropdown,
    'home-link': HomeLink,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = 'v' + VERSION;
  private changePasswordURL = '';
  private hasAnyAuthorityValues = {};
  public title = null;
  public description = null;

  @Watch('authenticated')
  onAuthenticated(value) {
    if (value && !this.isAdmin) {
      this.changePasswordURL = `${this.$store.getters.oktaUrl}enduser/settings`;
      if (!this.isReadAnnouncement) {
        this.openAnnouncement();
      }
    }
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public get userWidgets(): any[] {
    return this.$store.getters.getUserWidgets;
  }

  public get userId(): string {
    return this.$store.getters.account?.id || '';
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public async openAnnouncement() {
    const regionName = 'United States';
    const subregionName = 'U.S. all';
    await this.$store.dispatch('loadAnnouncement', { regionName, subregionName });
    this.$bvModal.show('announcement');
  }

  get isUser(): boolean {
    return this.$store.getters.account?.authorities?.includes('ROLE_USER');
  }
  get isAdmin(): boolean {
    return this.$store.getters.account?.authorities?.includes('ROLE_ADMIN');
  }

  get isDeveloper(): boolean {
    return this.$store.getters.account?.authorities?.includes('ROLE_DEVELOPER');
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get announcement() {
    return this.$store.getters.getAnnouncement;
  }

  public get isReadAnnouncement() {
    return this.$store.getters.account.isRecentAnnouncementRead;
  }

  public set isReadAnnouncement(value) {
    this.$store.getters.account.isRecentAnnouncementRead = value;
  }

  public async onReadAnnouncement() {
    if (!this.isReadAnnouncement) {
      await axios.patch(`api/interpreters/${this.userId}`, {
        id: this.userId,
        isRecentAnnouncementRead: true,
      });
      this.isReadAnnouncement = true;
    }
    this.$bvModal.hide('announcement');
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }
}
