import { Client, messageCallbackType } from '@stomp/stompjs';
import SockJS from "sockjs-client";

export default class BreakTrackerService  {
  private readonly client: Client;
  private onConnectCb?: () => void;
  public onDisconnectCb?: () => void;
  public onErrorCb?: (frame:any) => void;
  public onFatalErrorCb?: () => void;
  private _isConnected = false;

  constructor() {
    this.client = new Client({
      heartbeatIncoming: 10000,
      heartbeatOutgoing: 10000,
      reconnectDelay: 0,
      webSocketFactory: () => {
        return new SockJS(BreakTrackerService.buildUrl());
      },
      debug: console.log,
    });

    this.client.onConnect = () => {
      this._isConnected = true;
      this.onConnectCb && this.onConnectCb();
    };

    this.client.onDisconnect = () => {
      this._isConnected = false;
      this.onDisconnectCb && this.onDisconnectCb();
    };

    this.client.onWebSocketClose = () => {
      this.onFatalErrorCb && this.onFatalErrorCb();
    };

    this.client.onStompError = (frame: any) => {
      this.onErrorCb && this.onErrorCb(frame);
    };
  }

  get getClient(): any {
    return this.client;
  }

  get isConnected(): boolean {
    return this._isConnected;
  }

  public static buildUrl(): string {
    // building absolute path so that websocket doesn't fail when deploying with a context path
    const loc = window.location;
    const baseHref = document.querySelector('base')?.getAttribute('href');
    return  '//' + loc.host + baseHref + 'websocket/tracker';
  }

  public connect(onConnectCb: () => void, onDisconnectCb: () => void, onErrorCb: (frame:any) => void, onFatalErrorCb: () => void): void {
    this.onConnectCb = onConnectCb;
    this.onDisconnectCb = onDisconnectCb;
    this.onErrorCb = onErrorCb;
    this.onFatalErrorCb = onFatalErrorCb;

    this.client.activate();
  }

  public disconnect(): void {
    this.client.deactivate().then(r => r);
  }

  public subscribe(destination: string, cb: messageCallbackType): void {
    this.client.subscribe(destination, cb);
  }
}
