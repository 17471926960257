var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.authenticated
    ? _c(
        "b-nav-item-dropdown",
        {
          staticClass: "pointer",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            id: "account-menu",
            "menu-class": "black",
            "active-class": "active",
            "data-cy": "accountMenu",
          },
        },
        [
          _vm.authenticated
            ? _c(
                "span",
                {
                  staticClass: "navbar-dropdown-menu",
                  attrs: { slot: "button-content" },
                  slot: "button-content",
                },
                [
                  _c("div", { staticClass: "initials-block d-inline-block" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center initials",
                      },
                      [_vm._v(_vm._s(_vm.showInitials))]
                    ),
                    _vm._v(" "),
                    _vm.isUser
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            class: {
                              "clocked-in-bg status-circle":
                                _vm.isClockedIn && !_vm.isOnBreak,
                              "clocked-out-bg status-square":
                                _vm.isClockedIn === false && !_vm.isOnBreak,
                              "on-break-bg status-circle": _vm.isOnBreak,
                            },
                          },
                          [
                            _vm.isClockedIn && !_vm.isOnBreak
                              ? _c("font-awesome-icon", {
                                  staticClass: "icon-status",
                                  attrs: { icon: "fa-solid fa-play" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isOnBreak
                              ? _c("font-awesome-icon", {
                                  staticClass: "icon-status",
                                  attrs: { icon: "fa-solid fa-pause" },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-dropdown-item",
            { staticClass: "info-item", attrs: { disabled: "" } },
            [
              _c("div", [
                _vm._v(_vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName)),
              ]),
              _vm._v(" "),
              _vm.isUser
                ? _c("div", [
                    _vm.isOnBreak
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "on-break-status d-flex justify-content-center align-items-center",
                          },
                          [_vm._v("On Break")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isClockedIn && !_vm.isOnBreak
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "clocked-in-status d-flex justify-content-center align-items-center",
                          },
                          [_vm._v("Clocked In")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isClockedIn === false && !_vm.isOnBreak
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "clocked-out-status d-flex justify-content-center align-items-center",
                          },
                          [_vm._v("\n        Clocked Out\n      ")]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.authenticated
            ? _c(
                "b-dropdown-item",
                {
                  attrs: {
                    "data-cy": "logout",
                    id: "logout",
                    "active-class": "active",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "sign-out-alt" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Sign out")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.authenticated
            ? _c(
                "b-dropdown-item",
                {
                  attrs: {
                    "data-cy": "changePwd",
                    target: "_blank",
                    id: "changePwd",
                    "active-class": "active",
                  },
                  on: { click: _vm.changePasswordClick },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "fa-key" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Change password")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.authenticated
            ? _c(
                "b-dropdown-item",
                {
                  attrs: {
                    "data-cy": "login",
                    id: "login",
                    "active-class": "active",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.openLogin()
                    },
                  },
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: "sign-in-alt" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Sign in")]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-modal",
            { attrs: { id: "logOut", "hide-header": "", "hide-footer": "" } },
            [
              _c("div", [
                _c("div", { staticClass: "d-flex" }, [
                  _c("img", {
                    staticClass: "icon-warning",
                    attrs: {
                      src: require("./assets/stopsign-alert.png"),
                      alt: "warning",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mt-3 mb-3" }, [
                    _vm._v(
                      "\n          Signing out of VI Tool Chest will clock you out of your shift in TeamWork. Would you like to clock out?\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "button-block d-flex justify-content-end" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mr-3",
                        attrs: { variant: "primary" },
                        on: { click: _vm.confirmLogOut },
                      },
                      [_vm._v("Clock Out & Log Out")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "primary" },
                        on: { click: _vm.hideModal },
                      },
                      [_vm._v("Stay Clocked In")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }