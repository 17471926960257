import Vue from 'vue';
import Component from 'vue-class-component';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import Breadcrumb from "@/core/breadcrumb/breadcrumb.vue";

import '@/shared/config/dayjs';

@Component({
  components: {
    'jhi-navbar': JhiNavbar,
    'jhi-footer': JhiFooter,
    'breadcrumb': Breadcrumb
  },
})
export default class App extends Vue {}
