import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class AdminDropdown extends Vue {
  adminDropdownItems = [
    {
      link: '/admin/metrics',
      icon: 'tachometer-alt',
      name: 'Metrics',
    },
    {
      link: '/admin/health',
      icon: 'heart',
      name: 'Health',
    },
    {
      link: '/admin/configuration',
      icon: 'cogs',
      name: 'Configuration',
    },
    {
      link: '/admin/logs',
      icon: 'tasks',
      name: 'Logs',
    },
  ];

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }
}
