import axios, { AxiosPromise } from 'axios';

export default class LoginService {
  public login(loc = window.location) {
    const port = loc.port ? ':' + loc.port : '';

    loc.href = `//${loc.hostname}${port}/oauth2/authorization/oidc`;
  }

  public logout(): AxiosPromise {
    return axios.post('api/logout');
  }
}
