import { Authority } from '@/shared/security/authority';
import { Route } from 'vue-router';
/* tslint:disable */
// prettier-ignore
const Entities = () => import("@/entities/entities.vue");

// prettier-ignore
const Interpreter = () => import("@/entities/interpreter/interpreter.vue");
// prettier-ignore
const InterpreterUpdate = () => import("@/entities/interpreter/interpreter-update.vue");
// prettier-ignore
const InterpreterDetails = () => import("@/entities/interpreter/interpreter-details.vue");
// prettier-ignore
const Application = () => import("@/entities/application/application.vue");
// prettier-ignore
const ApplicationUpdate = () => import("@/entities/application/application-update.vue");
// prettier-ignore
const ApplicationDetails = () => import("@/entities/application/application-details.vue");
// prettier-ignore
const Region = () => import("@/entities/region/region.vue");
// prettier-ignore
const RegionUpdate = () => import("@/entities/region/region-update.vue");
// prettier-ignore
const RegionDetails = () => import("@/entities/region/region-details.vue");
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'users',
      name: 'Interpreter',
      component: Interpreter,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          { text: 'Users' },
        ],
      },
    },
    {
      path: 'interpreter/new',
      name: 'InterpreterCreate',
      component: InterpreterUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          {
            text: 'Users',
            to: { name: 'Interpreter' },
          },
          { text: 'New' },
        ],
      },
    },
    {
      path: 'interpreter/:interpreterId/edit',
      name: 'InterpreterEdit',
      component: InterpreterUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const interpreterId = route.params.interpreterId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Users',
              to: { name: 'Interpreter' },
            },
            {
              text: `${interpreterId}`,
              active: true,
            },
            {
              text: 'Edit',
              active: true,
            },
          ];
        },
      },
    },
    {
      path: 'interpreter/:interpreterId/view',
      name: 'InterpreterView',
      component: InterpreterDetails,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const interpreterId = route.params.interpreterId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Users',
              to: { name: 'Interpreter' },
            },
            {
              text: `${interpreterId}`,
              active: true,
            },
            {
              text: 'View',
              active: true,
            },
          ];
        },
      },
    },
    {
      path: 'application',
      name: 'Application',
      component: Application,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          { text: 'Applications' },
        ],
      },
    },
    {
      path: 'application/new',
      name: 'ApplicationCreate',
      component: ApplicationUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          {
            text: 'Applications',
            to: { name: 'Application' },
          },
          { text: 'New' },
        ],
      },
    },
    {
      path: 'application/:applicationId/edit',
      name: 'ApplicationEdit',
      component: ApplicationUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const applicationId = route.params.applicationId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Applications',
              to: { name: 'Application' },
            },
            {
              text: `${applicationId}`,
              active: true,
            },
            {
              text: 'Edit',
              active: true,
            },
          ];
        },
      },
    },
    {
      path: 'application/:applicationId/view',
      name: 'ApplicationView',
      component: ApplicationDetails,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const applicationId = route.params.applicationId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Applications',
              to: { name: 'Application' },
            },
            {
              text: `${applicationId}`,
              active: true,
            },
            {
              text: 'View',
              active: true,
            },
          ];
        },
      },
    },
    {
      path: 'region',
      name: 'Region',
      component: Region,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          { text: 'Regions' },
        ],
      },
    },
    {
      path: 'region/new',
      name: 'RegionCreate',
      component: RegionUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb: [
          {
            text: 'Home',
            to: { name: 'Dashboard' },
          },
          {
            text: 'Regions',
            to: { name: 'Region' },
          },
          { text: 'New' },
        ],
      },
    },
    {
      path: 'region/:regionId/edit',
      name: 'RegionEdit',
      component: RegionUpdate,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const regionId = route.params.regionId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Regions',
              to: { name: 'Region' },
            },
            {
              text: `${regionId}`,
              active: true,
            },
            {
              text: 'Edit',
              active: true,
            },
          ];
        },
      },
    },
    {
      path: 'region/:regionId/view',
      name: 'RegionView',
      component: RegionDetails,
      meta: {
        authorities: [Authority.ADMIN],
        breadCrumb(route: Route) {
          const regionId = route.params.regionId;
          return [
            {
              text: 'Home',
              to: { name: 'Dashboard' },
            },
            {
              text: 'Regions',
              to: { name: 'Region' },
            },
            {
              text: `${regionId}`,
              active: true,
            },
            {
              text: 'View',
              active: true,
            },
          ];
        },
      },
    },
  ],
};
