var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item",
    { attrs: { "active-class": "active", to: "/home", exact: "" } },
    [
      _c(
        "span",
        [
          _c("font-awesome-icon", { attrs: { icon: "home" } }),
          _vm._v(" "),
          _c("span", { staticClass: "no-bold" }, [_vm._v("Home")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }