import { ActionTree } from 'vuex';
import { WidgetsState } from '@/shared/config/store/dashboard/widgets/types';
import axios from 'axios';

export const actions: ActionTree<WidgetsState, any> = {
  async loadWidgets(state, userId): Promise<any> {
    const response = await axios.get(`api/interpreter-layouts/interpreter/${userId}`);
    if (response.data?.layouts) {
      state.commit('SET_LAYOUTS', response.data.layouts);
    }
    return response;
  },
  saveWidgets(_state, payload): Promise<any> {
    return axios.put('/api/interpreter-layouts', payload);
  },
};
