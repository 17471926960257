import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
import { Authority } from '@/shared/security/authority';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);

const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
const Dashboard = () => import('@/core/dashboard/dashboard.vue');

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/home",
      name: "Dashboard",
      component: Dashboard,
      meta: { authorities: [Authority.USER, Authority.ADMIN] }
    },
    {
      path: "/forbidden",
      name: "Forbidden",
      component: Error,
      meta: { error403: true }
    },
    {
      path: "/not-found",
      name: "NotFound",
      component: Error,
      meta: { error404: true }
    },
    ...admin,
    entities,
    ...pages
  ]
});

export default router;
