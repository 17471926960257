var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dropdown",
    {
      staticClass: "pointer",
      attrs: {
        right: "",
        id: "admin-menu",
        "active-class": "active",
        "data-cy": "adminMenu",
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "navbar-dropdown-menu",
          class: { "router-link-active": _vm.subIsActive("/admin") },
          attrs: { slot: "button-content" },
          slot: "button-content",
        },
        [
          _c("font-awesome-icon", { attrs: { icon: "users-cog" } }),
          _vm._v(" "),
          _c("span", { staticClass: "no-bold" }, [_vm._v("Administration")]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.adminDropdownItems, function (item, key) {
        return _c(
          "b-dropdown-item",
          { key: key, attrs: { to: item.link, "active-class": "active" } },
          [
            _c("font-awesome-icon", { attrs: { icon: item.icon } }),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(item.name))]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.openAPIEnabled
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/admin/docs", "active-class": "active" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "book" } }),
              _vm._v(" "),
              _c("span", [_vm._v("API")]),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }