import { Module } from "vuex";
import { AnnouncementsState } from "@/shared/config/store/dashboard/admin/announcements/types";
import { getters } from "@/shared/config/store/dashboard/admin/announcements/getters";
import { mutations } from "@/shared/config/store/dashboard/admin/announcements/mutations";
import { actions } from "@/shared/config/store/dashboard/admin/announcements/actions";

const state: AnnouncementsState = {
  announcement: {
    id: 0,
    content: "",
    createdBy: "",
    createdAt: "",
    expiresAt: "",
    region: {
      regionName: "",
      subregionName: ""
    }
  }
};

export const announcements: Module<AnnouncementsState, any> = {
  state,
  getters,
  mutations,
  actions
};
