import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EntitiesMenu extends Vue {
  menuItems = [
    {
      link: '/announcements',
      name: 'Announcements',
    },
    {
      link: '/vi/team/details',
      name: 'VI Team details',
    },
    {
      link: '/users',
      name: 'Users Management',
    },
    {
      link: '/region',
      name: 'Regions',
    },
    {
      link: '/application',
      name: 'Applications',
    },
  ];

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }
}
