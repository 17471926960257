import { Module } from 'vuex';
import { WidgetsState } from '@/shared/config/store/dashboard/widgets/types';
import { getters } from '@/shared/config/store/dashboard/widgets/getters';
import { mutations } from '@/shared/config/store/dashboard/widgets/mutations';
import { actions } from '@/shared/config/store/dashboard/widgets/actions';

const state: WidgetsState = {
  layouts: [
    { name: 'Shift Info', x: 0, y: 0, w: 4, h: 7, i: 'shift', path: '' },
    { name: 'TalentLMS', x: 4, y: 0, w: 4, h: 5, i: 'talent', path: '' },
    { name: 'Break Tracker', x: 0, y: 7, w: 4, h: 2, i: 'break-tracker', path: '' },
    { name: 'Team Break info', x: 4, y: 5, w: 2, h: 4, i: 'timeList', path: '' },
  ],
};

export const widgets: Module<WidgetsState, any> = {
  state,
  getters,
  mutations,
  actions,
};
