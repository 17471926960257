var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "app-container" },
      [_c("breadcrumb"), _vm._v(" "), _c("router-view")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }