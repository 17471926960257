var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.breadCrumbs
    ? _c("b-breadcrumb", {
        attrs: { items: _vm.breadCrumbs },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function (ref) {
                var item = ref.item
                return [
                  _c(
                    "b-breadcrumb-item",
                    { attrs: { to: item.to, active: item.active } },
                    [_vm._v("\n      " + _vm._s(item.text) + "\n    ")]
                  ),
                ]
              },
            },
          ],
          null,
          false,
          1313202845
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }