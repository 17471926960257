import { Module } from 'vuex';
import { Dayjs } from 'dayjs';

export interface AccountStateStorable {
  logon: boolean;
  userIdentity: null | any;
  authenticated: boolean;
  activeProfiles: string;
  adminDashboardUrl: string;
  oktaUrl: string;
  talentLmsUrl: string;
  teamWorkURL: string;
  externalId: string;
  xsrfToken: string;
  isClockedIn: boolean;
  isOnBreak: boolean;
  lastShiftEndTime: Dayjs;
}

export const defaultAccountState: AccountStateStorable = {
  logon: false,
  userIdentity: null,
  authenticated: false,
  activeProfiles: '',
  adminDashboardUrl: '',
  oktaUrl: '',
  talentLmsUrl: '',
  teamWorkURL: '',
  externalId: '',
  xsrfToken: '',
  isClockedIn: null,
  isOnBreak: null,
  lastShiftEndTime: null,
};

export const accountStore: Module<AccountStateStorable, any> = {
  state: { ...defaultAccountState },
  getters: {
    logon: state => state.logon,
    account: state => state.userIdentity,
    authenticated: state => state.authenticated,
    activeProfiles: state => state.activeProfiles,
    adminDashboardUrl: state => state.adminDashboardUrl,
    oktaUrl: state => state.oktaUrl,
    talentLmsUrl: state => state.talentLmsUrl,
    teamWorkUrl: state => state.teamWorkURL,
    xsrfToken: state => state.xsrfToken,
    isClockedIn: state => state.isClockedIn,
    externalId: state => state.externalId,
    isOnBreak: state => state.isOnBreak,
    lastShiftEndTime: state => state.lastShiftEndTime,
  },
  mutations: {
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
      state.xsrfToken = '';
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setAdminDashboardUrl(state, url) {
      state.adminDashboardUrl = url;
    },
    setOktaUrl(state, url) {
      state.oktaUrl = url;
    },
    setExternalId(state, id) {
      state.externalId = id;
    },
    setTalentLmsUrl(state, url) {
      state.talentLmsUrl = url;
    },
    setTeamWorkUrl(state, url) {
      state.teamWorkURL = url;
    },
    setXsrfCookie(state, xsrfToken) {
      state.xsrfToken = xsrfToken;
    },
    setIsClockedIn(state, value) {
      state.isClockedIn = value;
    },
    setIsOnBreak(state, value) {
      state.isOnBreak = value;
    },
    setLastShiftEndTime(state, value) {
      state.lastShiftEndTime = value;
    },
  },
};
