import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "Breadcrumb"
})
export default class Breadcrumb extends Vue {
  public get breadCrumbs() {
    if (typeof this.$route.meta.breadCrumb === "function") {
      return this.$route.meta.breadCrumb.call(this, this.$route);
    }
    return this.$route.meta.breadCrumb;
  }
}
